import { render, staticRenderFns } from "./product-item.vue?vue&type=template&id=df2bd352&scoped=true"
import script from "./product-item.vue?vue&type=script&lang=js"
export * from "./product-item.vue?vue&type=script&lang=js"
import style0 from "./product-item.vue?vue&type=style&index=0&id=df2bd352&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df2bd352",
  null
  
)

export default component.exports