<template>
  <div class="p">
    <!-- 购买次数 -->
    <div class="purchaseTime-wrapper" v-if="product.buysNum90day && product.buysNum90day > 0">
      <div class="purchaseTimes">买过{{ product.buysNum90day }}次</div>
    </div>
    <div class="purchaseTime-wrapper" v-else-if="product.goodsSaleQty90BillNoCnt">
      <div class="purchaseTimes">{{ product.goodsSaleQty90BillNoCnt }}人次购买</div>
    </div>

    <div class="pp" @click.stop="jumpdetail()">
      <div class="pic">
        <div class="pic-center">
          <img :src="product.productImg" />
        </div>

        <!-- 无货 -->
        <div class="product-center-warp" v-if="product.availableStock <= 0">
          <div class="bj">已售罄</div>
        </div>
        <!-- 视频红包-->
        <div class="product-center-warp" v-if="product.videoId" @click.stop="isstate = true">
          <div class="center-video"></div>
          <div class="center-text" v-if="!product.isWatch">观看视频领{{ product.videoRedPacketAmount }}元</div>
        </div>
        <!-- 返利 0差价标签 -->
        <div
          class="tag-bottom-right"
          v-if="product.activityVo && product.activityVo.rebate"
          :class="[product.activityVo && product.activityVo.rebate ? 'fy' : '']">
          <div class="c">返利</div>
        </div>
        <!-- 缺货，库存不足 -->
        <div class="bottom" v-if="product.availableStock > 0 && product.availableStock < 100">
          <span v-if="product.isWillNotHaveStock == 1">仅剩{{ product.availableStock }}{{ product.sellUnit }},即将缺货</span>
          <span v-else>仅剩{{ product.availableStock }}{{ product.sellUnit }}</span>
        </div>
        <!-- 对标原研 -->
        <div class="product-center-warp dbyy" v-if="product.productPatent=='1'">
          <div class="db_icon">
            <img src="https://obs.pujian.com/frontend/app/components/db_icon.png" />
          </div>
        </div>
          <div :class="['rgPs', product.isLearning && product.isLearning === 1 && product.areaExclusiveProductCategory === 0 ? 'xsPs' : '']" v-if="product.areaExclusiveProductCategory === 1 || (product.isLearning && product.isLearning === 1)">
              {{ product.areaExclusiveProductCategory === 1 ? '区域独家' : product.isLearning && product.isLearning === 1 ? '学术' : '' }}
          </div>
      </div>

      <!--商品价格组件-->
      <ProductPriceItem :key="product.productCode" :product="product" productType="productList" />

      <div class="product-name">
        <div class="product-name_title">
          <span v-html="product.productName"></span>
          【<span class="guige" v-html="product.specifications"></span>】
        </div>
      </div>

      <productLabels :itemObject="product"/>

      <!--商品的活动标签-->
      <ProductActivityTags ref="productActivityTagsRef" :key="product.productId" :product="product" />

      <div class="company margin11">
        <span v-html="product.produceFactory" />
      </div>

      <div class="yxq">
        <span style="margin-right: 10px;">效期：{{ product.nearEffectivePeriod }}</span>
        <template v-if="product.activityVo.grossMargin">毛利率{{ product.activityVo.grossMargin }}%</template>
      </div>
      <div class="thsj" style="font-size: 12px;">最后退货时间：{{ product.lastReturnTime }}</div>
      <div class="ns-price" style="display: flex; height: auto;">
        <span class="thsj" v-if="product.medicalInsurancePrice" style="font-size: 11px; color: #3875f6;">
          医保价: ￥{{ product.medicalInsurancePrice }}/{{ product.medicalUnit }}
          <span style="margin-left: 10px;">医保价差: ￥{{ product.medicalDifferencePrice }}</span>
        </span>
        <span v-else>零售价: {{ product.suggestedPrice + "/" + product.sellUnit }}</span>
      </div>
      <div class="syz" v-if="product.productInstruction">
        适应症:<span v-html="product.productInstruction" :title="product.productInstruction"></span>
      </div>
      <div class="zbz" v-if="product.isMiddlePack">
        <span>中袋装:(1中包装={{ product.packageNumber}}{{ product.sellUnit }})</span>
      </div>

      <!--相似商品标签-->
      <lowestPriceForSimilar ref="lowestPriceForSimilarRef" :product="product" :isShowSimilar="isShowSimilar" @showSimilar="showSimilar" />
      <img class="isControl" v-if="product.isFactoryControl === 1" src="https://obs.pujian.com/frontend/app/controlSell/controlSell.png" />
    </div>
    <!-- 购物车 -->
    <div v-if="product.isFactoryControl === 0">
        <div class="add-card margin11 v2-g-flex-row v2-g-flex-space-between" :class="product.availableStock <= 0 ? 'disabled' : ''" v-if="product.availableStock > 0">
            <div class="num v2-g-flex-column">
                <div class="num-real" v-if="product.isMiddlePack == 1 && product.packageNumber &&product.packageNumber > 0">
                    实际购买{{ product.packageNumber * buyCount }}{{ product.sellUnit }}
                </div>
                <div>
                    <a class="op" href="javascript:" @click.stop="addCount(-1)">
                        <i class="iconfont icon-sami-select"></i>
                    </a>
                    <input type="number"
                            v-model="buyCount"
                            ref="ballnum"
                            @keyup.enter="modifyCount()"
                            @change="modifyCount()"
                            @blur="modifyCount()"/>
                    <a class="op" href="javascript:" @click.stop="addCount(1)">
                        <i class="iconfont icon-add-select"></i>
                    </a>
                </div>
            </div>
            <button class="btn" type="button" @click.stop="addCard()">
                <img src="@/assets-v2/images/sy_gouwuche.png" style="width: 28px" />
                <span>加入购物车</span>
            </button>
        </div>
    </div>
    <div  class="isControlSale" v-else @click="lookPhone(product)">
        <div class="isControlSale_btn">查看代理联系方式</div>
    </div>
    <!--hover时，商品明细-->
    <div
      class="detail"
      v-if="!isShowSimilar"
      :style="{ left: detailLeft ? 'calc(-450px)' : 'calc(100% + 10px)' }">
      <p class="name">
        <span v-html="product.productName"></span>
        <span class="guige">【{{ product.specifications }}】</span>
      </p>

      <ul class="basic-info">
        <!--商品价格组件-->
        <ProductPriceItem :key="product.productCode" :product="product" productType="productListHover" />

        <li v-if="product.unitPrice && product.medicalInsurancePrice">
          <p>医保价</p>
          <p>￥{{ product.medicalInsurancePrice }}/{{ product.medicalUnit }}</p>
        </li>
        <li v-if="product.unitPrice && product.medicalInsurancePrice">
          <p>医保价差</p>
          <p>￥{{ product.medicalDifferencePrice }}</p>
        </li>
        <li v-else-if="product.suggestedPrice">
          <p>零售价</p>
          <p>￥{{ product.suggestedPrice + "/" + product.sellUnit }}</p>
        </li>
        <li v-if="product.activityVo.grossMargin">
          <p>毛利率</p>
          <p>{{ product.activityVo.grossMargin }}%</p>
        </li>
        <li v-if="product.activityVo.activityStrategyReduceVOS && product.activityVo.activityStrategyReduceVOS.length" class="actli">
          <p>活动</p>
          <!--商品的活动标签-->
          <ProductActivityTags ref="productActivityTagsRef" :key="product.productId" :product="product" />
        </li>
        <li>
          <p>包装</p>
          <p>{{ product.sellUnit }}</p>
        </li>
        <li>
          <p>件包装</p>
          <p>{{ product.packageNumber }}{{ product.sellUnit }}</p>
        </li>
        <li>
          <p>生产厂家</p>
          <p v-html="product.produceFactory"></p>
        </li>
        <li>
          <p>效期</p>
          <p>{{ product.nearEffectivePeriod }}</p>
        </li>
        <li>
          <p>最后退货时间</p>
          <p>{{ product.lastReturnTime }}</p>
        </li>
      </ul>
    </div>
    <Transition
      appear
      @before-appear="beforeEnter"
      @enter="enter"
      @after-appear="afterEnter">
      <div
        class="add-card-ball"
        v-if="showMoveDot"
        ref="ball"
        :style="{ top: top + 'px', left: left + 'px' }"
      ></div>
    </Transition>

    <VideoRedPacket :show="isstate" @close="isstate=false; getGoodsList()" :url="product.videoPlayUrl" :id="product.videoId" />
    <!--药品控制-->
    <addCarModal ref="addCarModalRef" :p_obj="p_obj"/>

    <!--过期/临期资质弹窗-->
    <qualificationsDialog ref="qualificationsDialogRef" @continueToAddPurchases="continueToAddPurchases" />
    <el-dialog :visible.sync="dl_modal" width="327px" :show-close="false">
        <supplier-ruler-pop :rulerData="dl_modal_obj" @popStatus="popStatus"></supplier-ruler-pop>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.p {
  &:hover {
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 10px #e5e5e5;
  }
  width: 226px;
  background: #ffffff;
  border: 1px solid #ededed;
  text-align: left;
  position: relative;
  cursor: pointer;

  .margin11 {
    margin-top: 9px;
  }
  .purchaseTime-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    overflow: hidden;
    z-index: 6;
    .purchaseTimes {
      position: absolute;
      top: -50px;
      left: -50px;
      font-size: 12px;
      width: 100px;
      height: 100px;
      background-color: #3875f6;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: center;
      transform-origin: center;
      color: #ffffff;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 3px;
    }
  }
  ::v-deep .el-dialog__header{
    padding: 0;
  }
  ::v-deep .el-dialog__body{
    padding: 0;
  }
  ::v-deep .el-dialog{
    border-radius: 20px;
  }
}
.pp {
  padding: 0 8px;
  position: relative;
}
.pp:hover ~ .detail {
  display: block;
}
.isControl{
  width: 45px;
  height: 45px;
  position: absolute;
  right: 20px;
  top: 210px;
}
.detail {
  display: none;
  position: absolute;
  right: auto;
  width: 450px;
  padding: 12px 12px;
  top: -10px;
  left: calc(100% + 10px);
  font-size: 0.85rem;
  font-weight: 400;
  z-index: 38;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 2px 1px rgb(0 1 0 / 20%);

  .name {
    font-size: 1rem;
    text-align: left;
    font-weight: 600;
  }

  .basic-info {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 10px 10px;

    li {
      display: flex;
      align-items: center;
      height: 30px;
      width: 100%;
      color: #666666;

      p {
        &:first-child {
          width: 85px;
          text-align: left;
        }
      }

      &.actli {
        height: inherit;
        line-height: inherit;
      }
    }
  }
}

.pic {
  width: 100%;
  height: 208px;
  position: relative;
  font-size: 0.85rem;
  font-weight: 400;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  .pic-center {
    margin: 13px auto;
    width: 174px;
    height: 174px;
    overflow: hidden;

    img {
      margin: 0 auto;
      width: 174px;
      height: 174px;
      display: block;
    }
  }

  .tag-top-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 27px;
    background: #00b0ff;
    color: #fff;
    text-align: center;
    line-height: 27px;
    background: linear-gradient(90deg, #4bb1ff 0%, #207cf5 100%);
    border-radius: 0 8px 0 8px;
  }

  .tag-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 43px;
    height: 42px;
    border-radius: 38px 0 4px 0;
  }

  .lcj {
    background: linear-gradient(0deg, #1b45f3 0%, #61c6fb 100%);
    color: #ffffff;

    .c {
      position: absolute;
      bottom: 3px !important;
      right: 2px !important;
      font-weight: 600;
    }

    .c1 {
      color: #fff;
      margin-top: 1px;
      padding: 0 10px;
      text-align: right;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .fy {
    background: linear-gradient(0deg, #077ca0 0%, #4ccfd0 100%);
    width: 43px;
    height: 40px;

    .c {
      position: absolute;
      bottom: 5px;
      right: 2px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .product-center-warp {
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 30%);
    top: 0;
    position: absolute;
    bottom: 0;
    z-index: 10;
    &.dbyy{
      background:none;
    }
    .db_icon{
      position: absolute;
      width: 120px;
      height: 120px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .bj {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      background: rgba(0, 0, 0, 0.32);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-align: center;
      line-height: 60px;
      font-size: 0.75rem;
      font-weight: 600;
    }
    .center-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28px;
      height: 28px;
      background: url("../../assets-v2/images/Videoredpacket/shipin_bofang.png");
      border-radius: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-align: center;
      line-height: 60px;
      font-size: 0.75rem;
      font-weight: 600;
    }
    .center-text {
      text-align: center;
      position: absolute;
      top: 70%;
      left: 50%;
      width: 100%;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #ffffff;
      transform: translate(-50%, -50%);
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 32px;
    background: rgb(0 0 0 / 30%);
    border-radius: 0 0 4px 4px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
  }
  .db_icon{
    position: absolute;
  }
    .rgPs{
        color: #ffffff;
        background: linear-gradient( 90deg, #FFAAB8 0%, #FF004E 100%);
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        font-size: 12px;
        padding: 0 10px;
        position: absolute;
        right: 0;
        top: 8px;
        z-index: 37;
        height: 20px;
        line-height: 20px;
    }
  .xsPs{
    background: linear-gradient( 90deg, #EB6F41 0%, #E03B29 100%);;
  }
}

.product-name {
  margin-top: 11px;
  font-size: 0.875rem;
  font-weight: 400;
  height: 20px;
  display: flex;
  align-items: center;
  width: 200px;

  .product-name_title{
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .jyb {
    display: inline-block;
    line-height: 18px;
    width: 18px;
    height: 18px;
    background: #3875f6;
    border-radius: 2px;
    color: #ffffff;
    text-align: center;
    font-size: 11px;
  }

  .guige {
    color: #666666;
    font-size: 12px;
  }
}

.ybbm {
  font-size: 12px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  display: flex;

  .bm {
    max-width: 180px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .fz {
    color: #3875f6;
  }
}

.company,
.yxq {
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.thsj {
  font-size: 0.875rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.syz {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  color: #666666;
}

.zbz,
.ns-price {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.add-card {
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #ededed;
  height: 44px;

  .btn {
    display: flex;
    align-items: center;
  }

  &.disabled {
    color: grey;

    .btn,
    input {
      color: #cccccc;
      pointer-events: auto;
    }

    img {
      filter: #cccccc;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
    }
  }

  .num {
    padding: 0 10px;
    justify-content: space-around;

    input {
      display: inline-block;
      width: 40px;
      height: 100%;
      border: none;
      text-align: center;
    }

    .num-real {
      line-height: 12px;
      font-size: 11px;
      height: 12px;
      color: #fe5257;
    }
  }

  .op {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #f5f7f9;
    text-align: center;
    line-height: 18px;
    vertical-align: middle;
    text-decoration: none;
  }

  .btn {
    cursor: pointer;
    color: #3875f6;
    border: none;
  }
}

.remind {
  margin-top: 5px;

  button {
    cursor: pointer;
    height: 34px;
    width: 100%;
    background: #fff2e6;
    border: none;
    color: #ff8212;
    border-radius: 5px;
  }

  &.disabled {
    button {
      background-color: #ededed;
      color: #666;
    }
  }
}

.add-card-ball {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 100px;
  position: fixed;
  z-index: 99999;
}
.isControlSale {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  cursor: pointer;

  .isControlSale_btn {
    width: 120px;
    height: 24px;
    background: #3875F6;
    border-radius: 19px;
    opacity: 1;
    color: #ffffff;
    line-height: 24px;
    padding: 0 5px;
    text-align: center;
    font-size: 12px;
  }
}
</style>

<script>
import mixin from "./mixin";
import qualificationsPopup from '@mixin/qualificationsPopup'
import productActivity from '@/components-v2/product/productActivity'
import Dialog from "../../components-v2/v2-dialog/index";
import VideoRedPacket from "./VideoRedPacket.vue";
import addCarModal from '../addCarModal/addCarModal.vue'
import qualificationsDialog from '@/components-v2/qualificationsDialog.vue'
import supplierRulerPop from "@/components-v2/supplierRulerPop/index.vue";
import lowestPriceForSimilar from '@/components-v2/lowestPriceForSimilar.vue'
import ProductActivityTags from '@/components-v2/ProductActivityTags.vue'
import ProductPriceItem from '@/components-v2/ProductPriceItem.vue'
import ProductLabels from "@/components-v2/product/productLabels.vue";

export default {
  mixins: [mixin, qualificationsPopup, productActivity],
  components: {
    supplierRulerPop,
    ProductLabels,
    Dialog,
    VideoRedPacket,
    addCarModal,
    qualificationsDialog,
    lowestPriceForSimilar,
    ProductActivityTags,
    ProductPriceItem
  },
    data(){
      return{}
    },
  methods: {
    getGoodsList() {
      this.$emit("getGoodsList");
    },
  }
};
</script>
