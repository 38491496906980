import { AccountStorage } from "@services/account";
import { mapActions } from "vuex";
import { kv } from '../../mixin/kv'

const accountStorage = new AccountStorage();
let mixin = {
    mixins: [kv],
    data() {
        return {
            buyCount: 1,
            isAddCard: false,
            top: 0,
            left: 0,
            showMoveDot: false,
            isstate: false,
            p_obj:{},
            activeInfoList: []
        };
    },
    props: {
        product: {
            type: Object,
            default:{}
        },
        pageCode: { type: String },//页面代码，统计板块分析－－目前只有弹出框调用传入了次参数（相识，凑单返利）
        isShopCard: { type: Boolean, default: false }, //是否购物车显示
        isShopCard_Promotion:{type:Boolean,default:false},//是否购物车优惠显示，优惠不显示多选
        isShowSimilar: { type: Boolean, default: false },//是否相似商品显示 ，相似商品不显示购物车动画，不显示相识商品操作
        detailLeft: { type: Boolean, default: false }, //鼠标放在图片上 显示明细，true显示在左边，false显示在右边

    },
    created() {
        this.defaultBuyNum();
    },
    watch: {
        product: {
            deep: true,
            immediate: true,
            handler(val) {
                this.defaultBuyNum();

                // 商品新接口逻辑
                if (val.activityVo && val.activityVo.preferentialType == 3) {
                  let arr = [val.activityVo.reward, val.activityVo.reward1];
                  this.activeInfoList = arr.map(item => {
                    return item.split(",")
                  })
                }
            }
        }
    },

    methods: {
        ...mapActions([
            'findCardList',
            'updateCard',
            'setIsAddCard'
        ]),

        showSimilar() {
            //显示相识商品
            this.$emit('showSimilar')
        },
        jumpdetail() {
            this.$router.push({ name: 'v2-detail', params: { id: this.product.productId, sale: this.buyCount } })
            this.$emit('closeDlg')
        },
        //获取活动价格
        getActivityPrice() {
            let price = 0;
            if (this.product.activityVOList && this.product.activityVOList.length > 0) {
                let filter = this.product.activityVOList.find(t => t.type == 1);
                if (filter != undefined) {
                    price = filter.activityZhPrice;
                }
            }
            return price;
        },
        addCount(num) {
            if (typeof (this.buyCount) == 'string') {
                this.buyCount = parseInt(this.buyCount)
            }
            if (this.buyCount <= 1 && num < 0) {
                return;
            }

            if (num == 1) {
              if (this.addCardVerify()) return
            }

            this.buyCount += num;

            if (num === -1) {
              if (this.verifySpecialOfferChooseNum()) return
            }

            if (this.isShopCard == true) {
                //购物车 需要提交后台
                const url = "/pjyy-deepexi-order-center/api/v1/cart/findCartList";
                let param = {
                    code: 1,
                    productId: [this.product.productId],
                    totalNumber: this.buyCount
                };
                this.$getHttpClient().post(url, param).then((r) => {
                    if (r.data.code == '200') {
                        this.updateCard(r.data.data);
                        this.freshShopCard();
                    }
                });
            }
        },
        modifyCount() {
          if (this.addCardVerify()) return
          if (this.verifySpecialOfferChooseNum()) return

            if (this.isShopCard) {
                //购物车 需要提交后台
                const url = "/pjyy-deepexi-order-center/api/v1/cart/findCartList";

                if (this.buyCount == null || this.buyCount == '') {
                    return;
                }
                if (this.product.limitSaleNumber != 0 || this.product.notLowerSale != 0) {
                    if (this.product.notLowerSale != 0) {
                        if (this.buyCount < this.product.notLowerSale) {
                            this.$messageTip('此商品最低购买数量：' + this.product.notLowerSale);
                            return;
                        }
                    }
                    if (this.product.limitSaleNumber != 0) {
                        if (this.buyCount > this.product.limitSaleNumber) {
                            this.$messageTip('此商品最大购买数量：' + this.product.limitSaleNumber);
                            return;
                        }
                    }
                }
                let param = {
                    code: 1,
                    productId: [this.product.productId],
                    totalNumber: Number(this.buyCount)
                };
                this.$getHttpClient().post(url, param).then((r) => {
                    if (r.data.code == '200') {
                        this.updateCard(r.data.data);
                        this.freshShopCard();
                    } else {
                        this.$messageTip(r.data.msg, 'error')
                        this.findCardList().then(() => {
                            this.freshShopCard();
                        })
                    }
                });
            }
        },
        // 默认购买数量
        defaultBuyNum() {
            let v = this.product;
            if (v.pluginFlag == 1) {
                this.buyCount = v.suggestAmount
            }
            if (v.totalNumber != null) {//如果是购物车
                this.buyCount = v.totalNumber
            } else {
                //是活动商品 并且活动推荐购买数量大于0
                if (v.activityVOList && v.activityVOList.length > 0 && v.activityVOList[0].activityCount) {
                    //如果是中包装 购买数量必须是中包装的倍数
                    if (v.isMediumPackage == 1) {
                        //判断活动商品的推荐购买数量 是否为 中包装控制数量的倍数
                        let count = v.activityVOList[0].activityCount / v.mediumPackage;
                        if (count > 0) {
                            //能够被整除说明是倍数 除以中包装数量得到实际购买数量
                            this.buyCount = count;
                            if (Math.floor(this.buyCount) === this.buyCount) {
                                this.buyCount = Math.round(this.buyCount)
                            } else {
                                this.buyCount = Math.ceil(this.buyCount)//中包装数量为小数时向上取整
                            }
                        } else {
                            //不能被整除说明活动推荐的购买数量不能满足中包装 默认给1
                            this.buyCount = 1
                        }

                        //如果存在最低销售限制
                        if (v.notLowerSale > 0) this.buyCount = v.notLowerSale
                    } else {
                        // 非中包装
                        if (v.activityVOList[0].type == 1) {
                            // 商品详情用到这个字段
                            if (v.maxCount) {
                                this.buyCount = v.maxCount;
                            } else if (v.activityVOList[0].activityCount) {
                                // 有活动时，默认使用这个字段
                                this.buyCount = v.activityVOList[0].activityCount;
                            } else {
                                // 两个字段都没有时，默认为1
                                this.buyCount = 1;
                            }
                        } else if (v.activityVOList[0].type == 4) {
                            // 品种红包数据
                            this.buyCount = v.activityVOList[0].activityCount;
                        } else if (v.isActivityGood) {
                            // 特价品种
                            this.buyCount = v.activityGoodInfo.activityCount
                        } else {
                            this.buyCount = 1
                        }
                    }
                } else {
                  // 新的商品接口逻辑
                  if (v.activityVo && v.activityVo.activityStrategyReduceVOS.length) {
                    // 中包装
                    if (v.isMiddlePack == 1) {
                      this.buyCount = v.meetWithActiveNum;
                    } else {
                      this.buyCount = v.activityVo.activityStrategyReduceVOS[0].condMeas;
                    }
                  } else {
                    this.buyCount = v.notLowerSale || 1 //存在最低限制则为最低限制数量，否则为1
                  }
                }
            }
        },
        //到货提醒
        async remind() {
            let data = {
                productId: this.product.productId
            };
            let url = `/pjyy-deepexi-product-center/api/v1/product/pcRegister/addRegister`;
            this.$getHttpClient()
                .post(url, data)
                .then(res => {
                    if (res.data.code === "200") {
                        this.$messageTip('提醒设置成功,商品到货后提醒你', 'success');
                        this.product.isCheckArrive = 1;
                        this.$forceUpdate()
                    }
                });
        },
        //取消提醒
        async cancelRemind() {
            let data = {
                productId: this.product.productId
            };
            let url = '/pjyy-deepexi-product-center/api/v1/product/pcRegister/deleteRegister';
            this.$getHttpClient()
                .post(url, data)
                .then(res => {
                    if (res.data.code === "200") {
                        this.$messageTip('已取消到货提醒', 'success');
                        this.product.isCheckArrive = 0;
                        this.$forceUpdate()
                    }
                });
        },
        //确定添加购物车
        addCardSubmit() {
            if (this.addCardVerify()) return;
            if (this.verifySpecialOfferChooseNum()) return;
            // 可用库存小于0
            if (this.product.availableStock <= 0) {
                return;
            }

            let data = {
                productId: this.product.productId || this.product.id,
                userId: Number(this.getUserId()),
                totalNumber: this.buyCount || 1
            };
            let $this = this;
            // 加入购物车
            const addGoodsToCartList = "/pjyy-deepexi-order-center/api/v1/cart/addGoodsToCartList";
            let rect = $this.$refs['ballnum'].getBoundingClientRect();

            this.$getHttpClient()
                .post(addGoodsToCartList, data)
                .then(res => {
                    if (res.data.code === "200") {
                      let { data } = res.data;
                      // 如果有资质过期的数据，显示资质过期弹窗
                      if (data.certificateList && data.certificateList.length) {
                        this.qualificationsPopup(2, data)
                        return
                      }

                        $this.$toast.mount($this.$toast.create("加入购物车成功"));
                        $this.setIsAddCard(true);

                        setTimeout(() => {
                            $this.setIsAddCard(false);
                        }, 1000);

                        $this.findCardList().then(() => {

                            $this.showMoveDot = true && !this.isShowSimilar;
                            $this.left = rect.left;
                            $this.top = rect.top;

                        });
                        //埋点

                        let kvCode = $this.pageCode || $this.getKvCode();

                        if (kvCode != 0) {
                            let pageSubCode = "";
                            if ($this.$route.name == 'v2-activity') {
                                pageSubCode = $this.$route.params.id;
                            }
                            $this.kv_addCard(kvCode, this.product.productId, pageSubCode);
                        }


                    } else if(res.data.code == "8501"){
                        this.p_obj = res.data.payload;
                        this.$refs.addCarModalRef.showModal()
                    }
                });
        },
        // 添加购物车
        addCard() {
          if (this.qualificationsPopup(1)) {
            return;
          }
          let num = this.product.packageNumber ? this.product.packageNumber : this.product.mediumPackage;
          let MiddlePack = this.product.isMiddlePack ? this.product.isMiddlePack : this.product.isMediumPackage;
          if (this.product.lastReturnTime == '不可退货' && MiddlePack == 1 && num > 0){
              const packageAmount = this.buyCount * num;
              const h = this.$createElement;
              this.$msgbox({
                  title: '温馨提示',
                  message: h('p', null, [
                      h('span', null, '该商品为中包装，1中包装='),
                      h('span', null, ` ${num}/${this.product.sellUnit}`),
                      h('p', null, [
                          h('span', null, `本次购买 `),
                          h('span', { style: 'color: red;font-weight: bold;' }, `${packageAmount}/${this.product.sellUnit}`)
                      ]),
                      h('span', null, '并且当前批次【不可退货】，确认购买吗?'),
                  ],),
                  showCancelButton: true,
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(action => {
                  this.addCardSubmit();
              }).catch(()=>{});
          } else if (this.product.lastReturnTime == '不可退货') {
            this.$confirm("当前批次【不可退货】，确认购买吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消"
            }).then(() => {
              this.addCardSubmit();
            }).catch(() => {
            })
          } else if(MiddlePack == 1 && num > 0){
              const packageAmount = this.buyCount * num;
              const h = this.$createElement;
              this.$msgbox({
                  title: '温馨提示',
                  message: h('p', null, [
                      h('span', null, '该商品为中包装，1中包装='),
                      h('span', null, ` ${num}/${this.product.sellUnit}`),
                      h('p', null, [
                          h('span', null, `本次购买 `),
                          h('span', { style: 'color: red;font-weight: bold;' }, `${packageAmount}/${this.product.sellUnit}`)
                      ]),
                  ],),
                  showCancelButton: true,
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(action => {
                  this.addCardSubmit();
              }).catch(()=>{});
          }else{
              this.addCardSubmit();
          }
        },
        getKvCode() {
            let code = 0;

            //埋点页面代码
            let name = this.$route.name;
            if (name == 'v2-search') {
                //综合搜索
                code = 12;
            }
            if (name == 'v2-plan') {
                //我的计划
                code = 14;
            }
            if (name == 'v2-activity') {
                //专题活动
                code = 1;
            }
            if (name == 'v2-hotProduct') {
                //专题活动
                code = 2;
            }
            if (name == 'v2-product') {
                let id = this.$route.params.id;
                switch (id) {
                    case '2':
                        code = 3;
                        break;
                    case '3':

                        code = 15;
                        break;
                    case '6':

                        code = 7;
                        break;
                    case '7':

                        code = 8;
                        break;
                    case '8':

                        this.kvCode = 9;
                        break;
                    case '9':

                        code = 17;
                        break;
                    case '10':

                        code = 11;
                        break;
                    case '20':

                        code = 6;
                        break;
                }
            }


            return code;
        },
        //获取用户ID
        getUserId() {
            let userInfo = accountStorage.getCacheUserInfo();
            if (userInfo) {
                return userInfo.userId;
            }
        },
        beforeEnter(el) {
            let style = el.style;
            style.transform = 'translate(0,0)';
            style.opacity = 0;
        },
        enter(el, done) {

            let t = document.getElementsByName('ccc')[0].getBoundingClientRect();

            let cardleft = t.left + t.width / 2;
            let cardTop = t.top + t.height / 2;
            let style = el.style;
            let x = cardleft - this.left;
            let y = cardTop - this.top;
            let $this = this;
            setTimeout(() => {
                style.transform = `translate(${x}px,${y}px)`;

                style.transition = 'all 0.5s cubic-bezier(0.12,0.78,0.53,0.92)';

                $this.showMoveDot = false;
            }, 100)

            done();

        },
        afterEnter(el) {
            let style = el.style;
            style.opacity = '1';
        },
      // 特价品种加入购物车前验证
      verifySpecialOfferChooseNum() {
        let { isMediumPackage, isActivityGood, activityGoodInfo, mediumPackage } = this.product;

        // 中包装实际购买数量
        let count = this.buyCount * mediumPackage;

        // 中包装品种
        if (isMediumPackage == 1) {
          // 特价品种，中包装实际购买数量不能小于起购数量
          if (isActivityGood && count < activityGoodInfo.activityCount) {
            this.buyCount = activityGoodInfo.activityCount / mediumPackage;
            this.$messageTip(`该商品为特价商品，购买的数量必须满足活动数量 ${activityGoodInfo.activityCount}`, 'warning')
            return true;
          }
        } else {
          // 特价品种，选购数量不能小于起购数量
          if (isActivityGood && this.buyCount < activityGoodInfo.activityCount) {
            this.$messageTip(`该商品为特价商品，购买的数量必须满足活动数量 ${activityGoodInfo.activityCount}`, 'warning')
            this.buyCount = activityGoodInfo.activityCount;
            return true;
          }
        }
        return false;
      },

      // 品种库存不足时，加入购物车验证
      addCardVerify() {
        let { isMediumPackage, mediumPackage, availableStock } = this.product;
        // 中包装实际购买数量
        let count = this.buyCount * mediumPackage;

        // 中包装品种
        if (isMediumPackage == 1) {
          // 中包装实际购买数量 > 品种库存数量
          if (count > availableStock) {
            this.$messageTip(`库存不足，可购买最大数量为${availableStock}`, 'warning')
            return true;
          }
        } else {
          // 非中包装品种 -- 选购数量 > 品种库存数量
          if (this.buyCount > availableStock) {
            this.$messageTip(`库存不足，可购买最大数量为${availableStock}`, 'warning')
            return true;
          }
        }
        return false;
      }
    }
}

export default mixin;
