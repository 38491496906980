import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Dialog', {
    attrs: {
      "show": _vm.showDlg,
      "paddingleft": "0",
      "paddingright": "0",
      "width": "859px",
      "height": "auto"
    },
    on: {
      "close": _vm.close
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('div', {
    staticClass: "video-title"
  }, [_c('div', [!_vm.getCustomerVideoViewdata.isView ? _c('b', {
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v("看视频返"), _c('label', {
    staticStyle: {
      "color": "#E5432E",
      "font-weight": "400"
    }
  }, [_vm._v(_vm._s(_vm.getCustomerVideoViewdata.amount) + "元")]), _vm._v("红包")]) : _vm._e(), _vm.getCustomerVideoViewdata.isView ? _c('b', {
    staticStyle: {
      "font-weight": "400"
    }
  }, [_c('label', {
    staticStyle: {
      "color": "#E5432E",
      "font-weight": "400"
    }
  }, [_vm._v("已返" + _vm._s(_vm.getCustomerVideoViewdata.amount) + "元红包")])]) : _vm._e()]), _c('div', {
    staticClass: "zt"
  }, [_c('label', {
    staticClass: "lb1",
    class: {
      'yk': _vm.getCustomerVideoViewdata.isView
    }
  }, [_vm._v(" " + _vm._s(_vm.getCustomerVideoViewdata.isView ? "已看" : "未看") + " ")]), _c('label', {
    staticClass: "lb2",
    class: {
      'yk': _vm.getCustomerVideoViewdata.isView
    }
  })])])]), _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('video', {
    ref: "my_videos",
    staticClass: "videos",
    attrs: {
      "src": _vm.url,
      "controls": "",
      "autoplay": ""
    },
    on: {
      "timeupdate": _vm.timeupdate,
      "play": _vm.controlsPlayVideo,
      "ended": _vm.ended
    }
  }), _c('div', {
    staticClass: "video-footer"
  }, [_c('div', {
    staticClass: "video-footer-left"
  }, [_vm._v(" 已观看 "), _c('span', [_vm._v(_vm._s(_vm.getCustomerVideoViewdata.viewCount))]), _vm._v(" 人 限 "), _c('span', [_vm._v(_vm._s(_vm.getCustomerVideoViewdata.maxViewCount))]), _vm._v(" 人观看 ")]), _c('div', {
    staticClass: "video-footer-right",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'v2-video'
        });
      }
    }
  }, [_vm._v(" 视频红包专区 ")])]), _vm.isstate ? _c('div', {
    staticClass: "video-img"
  }, [_c('div', {
    staticClass: "close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.isstate = false;
      }
    }
  }, [_c('label', {
    staticClass: "iconfont icon-close"
  })]), _c('div', {
    staticClass: "money"
  }, [_vm._v(" " + _vm._s(_vm.getCustomerVideoViewdata.amount.toFixed(2))), _c('label', {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("元")])]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.isstate = false;
      }
    }
  })]) : _vm._e()])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };